.NavBar .nav-link {
  font-size: .75rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  transition: none;
  color: #aaaaaa;
}

.NavBar.navbar {
  border-bottom: 0;
  letter-spacing: 1px;
  min-height: 50px;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: solid 1px transparent;
  transition: all .3s ease-in-out;
  font-weight: 700;
  padding: 0;
}

.NavBar.collapsed.navbar {
  padding: 0;
  background-color: #292929;
  border-bottom: solid 1px #191919;
}

.NavBar .navbar-toggler,
.NavBar .navbar-toggler:active,
.NavBar .navbar-toggler:focus {
  border: none;
  outline: none;
}

.NavBar .navbar-brand img {
  -webkit-backface-visibility: hidden;
  transition: all .25s ease-in-out;
}

.NavBar.collapsed .navbar-brand img {
  height: 35px;
}

.NavBar .navbar-dark .navbar-toggler {
  border: 0;
}

.NavBar .navbar-dark .nav-link {
  color: rgba(255,255,255,.5);
}

.NavBar .services-menu.show {
  background-color: #191919;
}

.NavBar .services-menu .dropdown-menu.show {
  background-color: #191919;
  margin-top: 7px;
  padding: 0;
  border: none;
  border-radius: 0;
}

.NavBar .services-menu .dropdown-menu a {
  color: #aaaaaa;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #292929;

  font-family: 'Lato', sans-serif;
  font-size: .75rem;
  letter-spacing: 1px;
  font-weight: 700;
  transition: none;
}

.NavBar .services-menu .dropdown-menu a:last-child {
  border-bottom: none;
}

.NavBar .services-menu .dropdown-menu a:hover,
.NavBar .services-menu .dropdown-menu a.active {
  color: white;
  background-color: #191919;
}

.NavBar .navbar-collapse .navbar-nav .nav-link {
  padding: 0 15px;
  line-height: 50px;
}

@media (max-width: 767px) {
  .NavBar.navbar {
    border-bottom: solid 1px #191919;
    background-color: #292929;
  }

  .NavBar .navbar-brand img {
    height: 35px;
    margin: 2px 16px;
  }

  .NavBar .navbar-collapse {
    padding: 4px 0;
    border-top: solid 1px #191919;
  }
}

@media (min-width: 768px) {
  .NavBar:not(.collapsed) .navbar-brand img {
    height: 75px;
    margin-top: 14px;
    margin-bottom: -14px;
  }

  .NavBar.navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .NavBar .dropdown-item {
    padding: .25rem 1rem;
  }
}
