.Hero {
  height: 400px;
  overflow: hidden;
  background-color: #212121;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-bottom: solid 2px #000;
  color: #eee;
}

@media (max-width: 769px) {
  .Hero {
    height: 250px;
    margin-top: -20px;
  }

  .Hero p {
    font-size: 17px;
  }

  .Hero h1 {
    font-size: 55px;
    text-align: center;
    padding-top: 90px;
  }

  .Hero h1.reduced {
    font-size: 40px;
    padding-top: 70px;
  }
}

@media (min-width: 768px) {
  .Hero {
    height: 400px;
  }

  .Hero p {
    font-size: 22px;
  }

  .Hero h1 {
    font-size: 90px;
    line-height: 1.1;
    padding-top: 150px;
    margin-top: 16px;
    margin-bottom: 10px;
  }

  .Hero h1.reduced {
    line-height: 1.25;
    font-size: 5vw!important
  }
}
