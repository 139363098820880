html, body {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  color: #383838;
}

a {
  color: #3371a8;
  transition: all .4s ease-in-out;
  cursor: pointer;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
  transition: color .3s ease-in-out, background-color .3s ease-in-out, border .3s ease-in-out;
  color: #000;
}

ul {
  padding-inline-start: 18px;
}

.background-dark-gray a,
.background-dark-blue a {
  color: #88CFFF;
  font-weight: bold;
}

.background-dark-gray a:hover,
.background-dark-blue a:hover {
  color: #ffffff;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .75rem;
}

/* This is flagged as important in the scss, so to override this has to be flagged important as well */
.text-primary {
  color: #428bca !important;
}

.icon-blue {
  color: rgb(51, 113, 168);
  font-size: .875rem;
}

.badge {
  font-weight: 400;
}

.text-muted {
  color: #757575 !important;
}

.btn,
.form-control,
.input-group-addon {
  border-radius: 20px;
}

button:focus {
  outline: 0;
}

.background-gray {
  background-color: #f1f1f1;
  color: #222;
}

.background-light-gray {
  background-color: #f8f8f8;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  color: #222;
}

.background-dark-gray {
  background-color: #333;
  color: #fff;
}

.background-dark-blue {
  background-color: #27303a;
  color: #fff;
}

.img-grayscale {
  filter: grayscale(1);
  transition: all .4s ease-in-out;
}

.img-grayscale:hover {
  filter: None;
}

.img-responsive {
  max-width: 100%;
}

.icon-image {
  display: block;
  max-width: 100%;
  height: auto;
  max-height: 200px;
}

textarea {
  resize: none;
}

.border-bottom-banner {
  border-bottom: 2px solid #000 !important
}

.text-white a {
  color: #ffffff;
}

.text-white a:hover {
  text-decoration: underline;
}

.rounded-xl {
  border-radius: 1rem !important;
}

@media (max-width: 768px) {
  body, p {
    font-size: 1rem
  }

  .lead {
    font-size: 1.2rem;
  }

  hr.my-5 {
    margin: 2rem 0 !important;
  }

  .main {
    padding-top: 48px;
  }
}

@media (min-width: 992px) {
  body, p {
    font-size: 1.125rem;
    line-height: 1.5;
  }

  .lead {
    font-size: 1.375rem;
  }
}

@media print {
  .col-print-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-print-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-print-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-print-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-print-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-print-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-print-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-print-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-print-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-print-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-print-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-print-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-print-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-print-first {
    -ms-flex-order: -1;
    order: -1;
  }

  .order-print-last {
    -ms-flex-order: 13;
    order: 13;
  }

  .offset-print-0 {
    margin-left: 0;
  }

  .offset-print-1 {
    margin-left: 8.333333%;
  }

  .offset-print-2 {
    margin-left: 16.666667%;
  }

  .offset-print-3 {
    margin-left: 25%;
  }

  .offset-print-4 {
    margin-left: 33.333333%;
  }

  .offset-print-5 {
    margin-left: 41.666667%;
  }

  .offset-print-6 {
    margin-left: 50%;
  }

  .offset-print-7 {
    margin-left: 58.333333%;
  }

  .offset-print-8 {
    margin-left: 66.666667%;
  }

  .offset-print-9 {
    margin-left: 75%;
  }

  .offset-print-10 {
    margin-left: 83.333333%;
  }

  .offset-print-11 {
    margin-left: 91.666667%;
  }
}
