.SocialButton:not(.SocialShareButton) {
  background: 0 0;
  display: inline-block;
  height: 2em;
  width: 2em;
  transition: all .2s ease-in-out;
  border-radius: 100%;
  text-align: center;
  font-size: 1em !important;
  line-height: 2em !important;
}

.SocialButton.twitter {
  color: #55acee;
  border: 1px solid #55acee
}

.SocialButton.linkedin {
  color: #007bb6;
  border: 1px solid #007bb6
}

.SocialButton.facebook {
  color: #3b5998;
  border: 1px solid #3b5998
}

.SocialButton.youtube {
  color: #FF0000;
  border: 1px solid #FF0000
}

.SocialButton.SocialShareButton.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
  color: #ffffff;
}

.SocialButton.SocialShareButton.linkedin {
  background-color: #007bb6;
  border: 1px solid #007bb6;
  color: #ffffff;
}

.SocialButton.SocialShareButton.facebook {
  background-color: #3b5998;
  border: 1px solid #3b5998;
  color: #ffffff;
}

.SocialButton.SocialShareButton.pinterest {
  background-color: #bd081c;
  border: 1px solid #bd081c;
  color: #ffffff;
}

.SocialButton.SocialShareButton.reddit {
  background-color: #ff4301;
  border: 1px solid #ff4301;
  color: #ffffff;
}

.SocialButton.active, .SocialButton:active, .SocialButton:focus, .SocialButton:hover {
  color: #fff
}

.SocialButton.active.twitter, .SocialButton:active.twitter, .SocialButton:focus.twitter, .SocialButton:hover.twitter {
  background: #55acee
}

.SocialButton.active.linkedin, .SocialButton:active.linkedin, .SocialButton:focus.linkedin, .SocialButton:hover.linkedin {
  background: #007bb6
}

.SocialButton.active.facebook, .SocialButton:active.facebook, .SocialButton:focus.facebook, .SocialButton:hover.facebook {
  background: #3b5998
}

.SocialButton.active.youtube, .SocialButton:active.youtube, .SocialButton:focus.youtube, .SocialButton:hover.youtube {
  background: #FF0000
}

.SocialButton.muted {
  color: #292929;
  background: #555;
  border: none;
}

.SocialButton.muted.active, .SocialButton.muted:active, .SocialButton.muted:focus, .SocialButton.muted:hover {
  border: none;
  color: #fff
}

.SocialButton.large {
  color: #666;
  border: 1px solid #666;
  font-size: 1.25em !important;
}

.SocialButton.large.active, .SocialButton.large:active, .SocialButton.large:focus, .SocialButton.large:hover {
  color: #fff
}

.SocialButton.large.active.twitter, .SocialButton.large:active.twitter, .SocialButton.large:focus.twitter, .SocialButton.large:hover.twitter {
  background: #55acee;
  border: 1px solid #55acee
}

.SocialButton.large.active.linkedin, .SocialButton.large:active.linkedin, .SocialButton.large:focus.linkedin, .SocialButton.large:hover.linkedin {
  background: #007bb6;
  border: 1px solid #007bb6
}

.SocialButton.large.active.facebook, .SocialButton.large:active.facebook, .SocialButton.large:focus.facebook, .SocialButton.large:hover.facebook {
  background: #3b5998;
  border: 1px solid #3b5998
}

.SocialButton.large.active.youtube, .SocialButton.large:active.youtube, .SocialButton.large:focus.youtube, .SocialButton.large:hover.youtube {
  background: #FF0000;
  border: 1px solid #FF0000
}
