
.slider-wrapper {
  background: #272f3a;
  background: linear-gradient(#1e2023, #272f3a);
  border-bottom: solid 2px #111;
}

.slider-title {
  font-weight: 300;
  height: 40px;
  padding: 0 15px !important;
  font-size: 30px;
  line-height: 37px;
  color: #fff;
  border-radius: 20px;
  white-space: nowrap;
}

.slider-subtitle {
  font-size: 30px;
  color: #fff;
  padding: 0 18px !important;
  white-space: nowrap;
}

.slider-title-a {
  background: #2772b3
}

.slider-subtitle-a {
  color: #468cc8
}

.slider-title-b {
  background: #468cc8
}

.slider-subtitle-b {
  color: #468cc8
}

.slider-title-c {
  background: #42ccff
}

.slider-subtitle-c {
  color: #42ccff
}

.slider-title-d {
  background: #2772b3
}

.slider-subtitle-d {
  color: #468cc8
}

.demo-slider-title {
  font-weight: 400;
  height: 40px;
  padding: 10px 20px;
  font-size: 30px;
  line-height: 37px;
  color: #fff;
  border-radius: 30px;
  white-space: nowrap
}

#layerslider {
  width: 100%;
  height: 100vh;
  min-height: 240px
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  #layerslider {
    height: 768px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  #layerslider {
    height: 1024px;
  }
}
