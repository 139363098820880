.ContactPage .map-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: auto;
  background-image: url("../../assets/images/contact/map-clear.png");
}

.ContactPage .bnl-alt-logo {
  height: 70px;
  width: 70px;
}
