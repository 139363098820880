.QuoteBubble {
  position: relative;
  padding: 12px;
  background: #f4f4f4;
  border-radius: 10px;
}

.QuoteBubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: #f4f4f4 transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -15px;
  right: 75px
}
