.ContactForm .input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}

.ContactForm .alert-contact {
  padding: 2px 16px;
  border-radius: 20px;
  margin-bottom: 0
}

.ContactForm .input-group > textarea.form-control,
.ContactForm .input-group > input.form-control {
  resize: none;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.ContactForm .input-group-text {
  background-color: #f1f1f1;
}
