.CookieConsent.alert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  color: #fff;
  padding: 1.75em;
  background: #242a32;
}

.CookieConsent.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition-delay: 1000ms;
}

.CookieConsent a {
  text-decoration: underline;
  font-size: .9rem;
  font-weight: 400;
  padding: 0 15px;
}
