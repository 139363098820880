/*.Blog .btn-default {*/
/*  color: #fbfbfb;*/
/*  background-color: transparent;*/
/*  border: 1px solid #fbfbfb;*/
/*}*/

@media (min-width: 62rem) {
  .Blog .card-text {
    font-size: 0.9375rem;
  }
}
