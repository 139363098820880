.BlogDetail .img-blog {
  height: auto;
  width: 100%;
  max-height: 800px;
  max-width: 100%;
  opacity: 0;
  transition: opacity .4s;
}

.BlogDetail .img-blog.loaded {
  opacity: 1;
}

.BlogDetail a .card-body {
  color: #444444;
}

.BlogDetail a:hover .card-body {
  color: #3371a8;
}

@media (max-width: 767px) {
  .BlogDetail .blog-title-row {
    margin: 19px 0 0;
  }
}

@media (min-width: 768px) {
  .BlogDetail .blog-title-row {
    margin: 30px 0 0;
  }
}

@media (min-width: 992px) {
  .BlogDetail .blog-detail-meta {
    text-align: right;
    border-right: 1px solid #eaeaea;
    margin-top: 25px;
  }
}

.BlogDetail .blog-background-image {
  min-height: 100px;
  width: 100%;
  background-color: #3b3b3b;
  position: relative;
}

.BlogDetail .blog-background-image img {
  max-width: 100%;
}

.BlogDetail .blog-detail-image {
  min-height: 100px;
  width: 100%;
  top: 0;
  position: absolute;
}

.BlogDetail .blog-darken-gradient {
  min-height: 280px;
  width: 100%;
  position: absolute;
  top: 0;
}

@media (max-width: 767px) {
  .BlogDetail .blog-detail-image {
    top: 50px;
  }
}

@media (min-width: 768px) {
  .BlogDetail .blog-darken-gradient {
    background: linear-gradient(rgba(20, 20, 20, .7), rgba(20, 20, 20, 0));
  }
}

.BlogDetail code {
  color: #02498B;
  background-color: #ecf3f9;
  font-size: 82%;
}

.BlogDetail pre.prettyprint {
  line-height: 1.6em;
  color: #f8f8f8;
  background-color: #333;
  padding: 1.3em;
  border-radius: 6px;
  border-color: #111;
}

.BlogDetail pre.prettyprint code {
  background-color: inherit;
}

.BlogDetail code .str,
.BlogDetail pre .str {
  color: #65B042;
}

.BlogDetail code .kwd,
.BlogDetail pre .kwd {
  color: #E28964;
}

.BlogDetail code .com,
.BlogDetail pre .com {
  color: #AEAEAE;
  font-style: italic;
}

.BlogDetail code .typ,
.BlogDetail pre .typ {
  color: #89bdff;
}

.BlogDetail code .lit,
.BlogDetail pre .lit {
  color: #3387CC;
}

.BlogDetail code .pln,
.BlogDetail code .pun,
.BlogDetail pre .pln,
.BlogDetail pre .pun {
  color: #fff;
}

.BlogDetail code .tag,
.BlogDetail pre .tag {
  color: #89bdff;
}

.BlogDetail code .atn,
.BlogDetail pre .atn {
  color: #bdb76b;
}

.BlogDetail code .atv,
.BlogDetail pre .atv {
  color: #65B042;
}

.BlogDetail code .dec,
.BlogDetail pre .dec {
  color: #3387CC;
}

.BlogDetail .body img, .BlogDetail .body video {
  display: block;
  margin: 1.5em auto;
  height: auto;
}

.BlogDetail .body .kg-image {
  margin: 0 auto;
  max-width: 100%;
}

.BlogDetail .body .kg-width-full,
.BlogDetail .body .kg-width-wide {
  margin-left: -100%;
  margin-right: -100%;
}

.BlogDetail .body .kg-width-full .kg-image {
  max-width: 100vw;
}

.BlogDetail .body .kg-width-wide .kg-image {
  max-width: 1040px;
}

@media (max-width: 1040px) {
  .BlogDetail .body .kg-width-wide .kg-image {
    max-width: 100vw;
  }
}

.BlogDetail .body figcaption,
.BlogDetail .body figcaption {
  margin: 1em auto 0;
  color: #5d7179;
  font-size: 75%;
  line-height: 1.5em;
  text-align: center;
  max-width: 1040px;
}

.BlogDetail .body .kg-embed-card {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.BlogDetail .body .kg-embed-card iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
