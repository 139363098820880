.Footer .footer-title {
  font-size: .75rem;
  line-height: 2.25rem;
  color: #fcfcfc;
  font-weight: 600;
  text-transform: uppercase;
}

.Footer a {
  font-size: 0.9375rem;
  line-height: 1.75rem;
  color: #fff;
  font-weight: 700;
}

.Footer a:hover,
.Footer a:focus {
  color: #fff;
  text-decoration: none;
}

.Footer .footer-logo {
  padding-top: 10px;
  margin-bottom: 24px;
  width: 50%;
  max-width: 120px;
}

.Footer {
  background-color: #333;
  bottom: 0;
  letter-spacing: .03rem;
  color: #fff;
}

.Footer .Copyright {
  background-color: #292929;
  padding: 0.625rem 0;
  line-height: 2.1875;
  color: #777777
}

.Footer .copyright-text {
  font-size: .875rem;
}

@media (max-width: 767px) {
  .Footer {
    text-align: center;
  }

  .Footer a {
    font-size: 0.85rem;
  }
}

