.btnSection a {
    display: block;
    float: left;
}

.learnMoreBtn {
    border: 2px solid #cdcdcd;
    display: block;
    border-radius: 46px;
    width: 200px;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    overflow: hidden;
    margin-right: 10px;
}
.learnMoreBtn:hover {
    border: 2px solid transparent;
    transform: scale(1.05);
    will-change: transform;
    text-decoration: none;
}

.learnMoreDownload {
    border: 2px solid #cdcdcd;
    display: block;
    border-radius: 46px;
    width: 55px;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    overflow: hidden;
    color: #fff;
}

.hiddenLearnMoreDownload {
    visibility: hidden;
}

